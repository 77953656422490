<template>
  <div v-if="authUser">
    <profile-bar />

    <div class="display-1 text-center my-8">
      <v-avatar size="50" class="mr-2" contain tile>
        <v-img src="/img/flaticons/gift-voucher.png"></v-img>
      </v-avatar>
      <span>{{ $lang(`RedeemCoupon`) }}</span>
    </div>

    <v-row class="justify-center">
      <v-col cols="12" md="6">
        <v-card id="coupon-entry-form" elevation="7" class="rounded-xl">
          <v-card-text>
            <v-text-field
              height="50"
              autofocus
              :label="$lang(`EnterCouponCode`)"
              placeholder="Ex: 43DCDE233"
              outlined
              v-model="couponCode"
              :loading="loading"
              @keyup.enter="checkCouponCode"
            />
            <v-btn
              :loading="loading"
              :disabled="loading || !couponCode || couponCode.length < 2"
              block
              large
              class="primary rounded-xl"
              @click="checkCouponCode"
            >
              {{ $lang("submit") }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      class="justify-center"
      v-if="availableCoupons && availableCoupons.length > 0"
    >
      <v-col cols="12">
        <div class="text-center pt-8 pb-4">
          {{ isHindi ? "उपलब्ध कूपन" : "Available coupons" }}
        </div>
      </v-col>
      <v-col cols="12" md="6" v-for="item in availableCoupons" :key="item.id">
        <v-card outlined class="rounded-xl" style="border: 1px dashed green">
          <v-card-title class="headline">
            <span @click="handleCouponCodeClick(item)" class="coupon-code">{{
              item.uid.toUpperCase()
            }}</span>
            <v-spacer></v-spacer>
            <span>{{ $lang(item.currency, [item.amount + ""]) }}</span>
          </v-card-title>
          <v-card-text class="text-center"
            >{{
              isHindi
                ? item.title_hi
                  ? item.title_hi
                  : item.title
                : item.title
            }}
          </v-card-text>
          <v-card-text
            class="green darken-4 white--text ma-0 py-2 caption text-center"
          >
            {{
              $lang("YOUGETCOINS", [
                (
                  parseInt(item.data.vcoin) + parseInt(item.data.gcoin)
                ).toString(),
              ])
            }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-divider
      v-if="availableCoupons && availableCoupons.length > 0"
      class="mt-8"
    ></v-divider>
    <v-row
      class="justify-center"
      v-if="redeemedCoupons && redeemedCoupons.length > 0"
    >
      <v-col cols="12">
        <div class="text-center pt-8 pb-4">
          {{ isHindi ? "मेरे रीडीम किए हुए कूपन" : "My redeemed coupons" }}
        </div>
      </v-col>
      <v-col cols="12" md="6" v-for="item in redeemedCoupons" :key="item.id">
        <v-card outlined class="rounded-xl">
          <v-card-title class="headline">
            <span class="coupon-code coupon-code-inactive">{{
              item.uid.toUpperCase()
            }}</span>
            <v-spacer></v-spacer>
            <span>{{ $lang(item.currency, [item.amount + ""]) }}</span>
          </v-card-title>
          <v-card-text class="text-center"
            >{{
              isHindi
                ? item.title_hi
                  ? item.title_hi
                  : item.title
                : item.title
            }}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text class="ma-0 py-2 caption text-center">
            {{
              $lang("COINSCREDITEDYOURWALLET", [
                (
                  parseInt(item.data.vcoin) + parseInt(item.data.gcoin)
                ).toString(),
              ])
            }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <AnimOverlay @attached="handleAnimAttached" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import ProfileBar from "@/app/profile/modules/ProfileBar";
import AnimOverlay from "./AnimOverlay.vue";
import { scrollToTargetAdjusted } from "@/helpers/utils";
export default {
  name: "redeem-coupon",
  components: {
    ProfileBar,
    AnimOverlay,
  },

  data() {
    return {
      loading: false,
      couponCode: "",
      redeemedCoupons: null,
      availableCoupons: null,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  watch: {},
  methods: {
    handleCouponCodeClick(item) {
      let elm = document.getElementById("coupon-entry-form");
      scrollToTargetAdjusted(elm);
      this.couponCode = item.uid.toLowerCase();
    },
    handleAnimAttached(animator) {
      this.animator = animator;
    },
    checkCouponCode() {
      if (!this.couponCode) {
        return;
      }
      this.loading = true;
      return axios
        .get("/coupons/redeem/" + this.couponCode)
        .then((res) => {
          this.animator.animate("confetti");
          setTimeout(() => {
            this.couponCode = null;
            this.$store.dispatch("auth/fetchUser");
            this.fetchRedemedCoupons(1);
            this.fetchAvailableCoupons(1);
          }, 1000);
        })
        .catch(() => {
          // this.animator.success();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchRedemedCoupons(page = 1) {
      return axios
        .get("coupons/redeemed?orderby=coupons.id|desc&perPage=30&page=" + page)
        .then((res) => {
          this.redeemedCoupons = res.data.data;
          // this.redeemedCoupons = data;
        })
        .finally(() => {});
    },
    fetchAvailableCoupons(page = 1) {
      return axios
        .get(
          "coupons/available?orderby=coupons.id|desc&perPage=30&page=" + page
        )
        .then((res) => {
          this.availableCoupons = res.data.data;
        })
        .finally(() => {});
    },
  },
  created() {
    this.fetchRedemedCoupons(1);
    this.fetchAvailableCoupons(1);
  },
  mounted() {
    // setTimeout(() => {
    //   if (this.animator) {
    //     this.animator.animate("coupon");
    //   }
    // }, 500);
  },
};
</script>

<style scoped>
.coupon-code {
  padding: 0.2em 0.8em;
  border: 1px dashed green;
  color: green;
  border-radius: 2em;
  cursor: pointer;
  box-shadow: 0 0 0em;
  transition: 0.3s ease-in-out;
}
.coupon-code-inactive {
  border: 1px dashed grey;
  color: grey;
}
.coupon-code:hover {
  box-shadow: 0 0 0.4em;
}
</style>