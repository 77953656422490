<template>
  <lottie-animation
    style="
      position: fixed;
      top: 0;
      left: 0;
      pointer-events: none;
      z-index: 1000;
    "
    :path="path"
    :loop="false"
    :autoPlay="false"
    :speed="1"
    @AnimControl="setAnimController"
  />
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import bus from "@/bus";
export default {
  name: "anim-overlay",
  props: [""],
  components: {
    LottieAnimation,
  },
  data() {
    return {
      anim: {},
      animString: "",
    };
  },
  computed: {
    path() {
      switch (this.animString) {
        case "confetti":
          return `./lottie/74694-confetti.json`;
        case "correct":
          return `./lottie/86756-correct-green.json`;
        case "success":
          return `./lottie/74694-confetti.json`;
        case "incorrect":
          return `./lottie/86757-wrong.json`;
        case "failed":
          return `./lottie/56947-icon-failed.json`;
        case "coupon":
          return `./lottie/47987-hand-anim.json`;
        default:
          return `./lottie/74694-confetti.json`;
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    playAnimation(str) {
      this.animString = str;
      setTimeout(() => {
        this.anim.play();
        this.anim.show();
        setTimeout(() => {
          this.anim.stop();
          this.anim.hide();
        }, 2500);
      }, 500);
    },
    setAnimController(anim) {
      this.anim = anim;
      console.log("mounted", this.anim);
      this.$emit("attached", {
        animate: this.playAnimation,
      });
    },
  },
};
</script>

<style>
</style>